.about__cover {
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap-reverse;
  margin-top: 75px;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(238, 238, 238, 0.719);
  @include media("<=tablet") {
    padding-top: 60px;
    flex-direction: column-reverse;
  }
  .author-image {
    max-width: 400px;
    max-height: 400px;
    width: 100%;
  }
}
.about {
  height: 100%;
  display: flex;
}
.blurb {
  margin: 0 30px;
  max-width: 600px;
  text-indent: 30px;

  @include media("<=phone") {
    max-width: 300px;
  }
}
