.readmore {
  height: 100%;
  justify-content: center;
  align-items: center;
}
.readmore_page {
  width: 100%;
  margin: auto;
  display: flex;
  margin-top: 75px;
  justify-content: space-evenly;
  align-items: center;
}
.read_more {
  width: 100%;

  background: rgba(238, 238, 238, 0.719);
}
.readmore_info {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  margin-top: 75px;

  margin-bottom: 75px;
  justify-content: space-evenly;
  align-items: center;
  @include media("<=tablet") {
    padding-top: 60px;
    flex-direction: column-reverse;
  }
}
.readmore_image {
  max-height: 400px;
}
