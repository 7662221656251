@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import "./breakpoints.scss";
@import "./HomePage.scss";
@import "./About.scss";
@import "./readmore.scss";
body,
html,
#root {
  min-height: -webkit-fill-available;
  height: 100%;
  padding: 0;
  overflow: auto;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ::selection {
    background: rgba($color: #5555557c, $alpha: 0.5);
  }
}
* {
  -webkit-overflow-scrolling: touch;
}
html {
  height: 100;
  z-index: 0;
  background-image: url("/Images/boat.png");
  background-size: cover;
  background-position: center;
}
.text-center {
  text-align: center;
}
.flex-nowrap {
  height: 100%;
  display: flex;
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
.noflex {
  display: unset;
}
nav {
  z-index: 2;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid rgba(37, 37, 37, 0.2);
  top: 0;
  width: 100%;
  height: 50px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.2);
  @include media("<=phone") {
    border-radius: 0 0 30px 30px;

    justify-content: space-evenly;
  }
  .NavLink {
    margin: 0 20px;
    @include media("<=phone") {
      margin: 0;
    }
    text-decoration: none;

    color: #212121;
    h1 {
      transition: transform 0.3s;
      -webkit-transition: transform 0.3s;
    }

    &:hover h1 {
      transform: scale(1.05);
      transition: transform 0.3s;
      -webkit-transition: transform 0.3s;
    }
    h1 {
      font-weight: 500;
    }
  }
  .dropdown {
    user-select: none;
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;

    position: absolute;
    left: 50%;
    background-color: #f9f9f9;
    transform: translateX(-50%);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    width: 200px;
    z-index: 1;
    opacity: 0;
    a {
      text-align: center;
      font-size: 1rem;
      text-decoration: none;
      font-weight: bold;
      color: blue;
      margin: 20px 0;
    }
  }
  .dropdown:hover .dropdown-content,
  .dropdown:focus .dropdown-content {
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition-delay: 0.1s;
    transition: opacity 0.2s;
    -webkit-transition: opacity 0.2s;
  }
  .contact-div {
    position: absolute;
    right: 5%;
    @include media("<=tablet") {
      display: none;
    }
    .contactme {
      user-select: none;
    }
    p {
      font-family: "Roboto";

      margin: 0;
      font-weight: 500;
      color: #212121;

      a {
        margin: 0;
        text-decoration: none;

        color: #913636;
      }
    }
  }
}
