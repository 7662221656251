.cover {
  position: relative;
  width: 100%;
  min-height: 100%;
  background: rgba(255, 255, 255, 0.521);
  /* Hibberd V. B. Kline, III Adventures-in-Time
   Navy Gray Novels of the Civil War at Sea */
  .intro {
    width: 100%;
    padding-top: 60px;
    justify-content: space-evenly;
    align-items: center;
    // background: rgba(255, 255, 255, 0.356);
    .title-banner {
      flex-basis: 50%;
      animation: fadeIn ease 1s;
      -webkit-animation: fadeIn ease 1s;
      -moz-animation: fadeIn ease 1s;
      -o-animation: fadeIn ease 1s;
      -ms-animation: fadeIn ease 1s;
      max-width: 600px;
      min-width: 600px;
      font-weight: bold;

      font-size: 1rem;
      @include media("<=tablet") {
        padding-top: 10%;
        min-width: 200px;
        font-size: 1rem;
      }
      @include media("<=phone") {
        min-width: 200px;
        font-size: 1rem;
      }
      .title {
        font-family: "Garamond";
        margin: 1px 0;
      }
      h3 {
        font-weight: 800;
        letter-spacing: 1px;
      }
    }
    .home-intro {
      font-family: "Times New Roman", Times, serif;
      opacity: 0;
      animation: fadeIn ease 1s forwards;
      -webkit-animation: fadeIn ease 1s forwards;
      -moz-animation: fadeIn ease 1s;
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.5s;
      width: 600px;

      min-width: 400px;
      p {
        margin: 0 5px;
        margin-bottom: 1px;
      }

      @include media("<=tablet") {
        padding-top: 10%;
        min-width: 200px;
        font-size: 1rem;
      }
      @include media("<=phone") {
        font-size: 1rem;
      }
    }
  }
  /*Book Container*/
  .book-list {
    padding-top: 20px;
    justify-content: center;
    .book-container {
      position: relative;
      background-color: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(1px);
      border-radius: 5px;
      transition: box-shadow 0.3s;
      -webkit-transition: box-shadow 0.3s;
      transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;

      margin: 10px 10px;
      min-width: 200px;
      flex-basis: 25%;

      flex-shrink: 0.2;
      display: flex;
      flex-direction: column;
      @include media("<=tablet") {
        min-width: 90%;
      }
      .book-info {
        // height: 90%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        transition: background-color 0.3s;
        -webkit-transition: background-color 0.3s;
        img {
          transition: box-shadow 0.3s;
          -webkit-transition: box-shadow 0.3s;
        }
        &:hover {
          img {
            box-shadow: 0 0 10px #000;
            transition: box-shadow 0.3s;
            -webkit-transition: box-shadow 0.3s;
          }
          h3 {
            color: rgb(143, 37, 37);
          }
          // background-color: rgba(192, 192, 192, 0.7);
          transition: background-color 0.3s;
          -webkit-transition: background-color 0.3s;
        }
      }
      .book-image {
        display: block;
        // max-height: 500px;
        width: 50%;
        margin: 0 auto;
      }
      .book-intro {
        width: 80%;
        flex-grow: 1;
        color: black;
        flex-basis: auto;
        margin-left: auto;
        margin-right: auto;
      }
      .learnMore {
        position: absolute;
        top: 0%;
        right: 0%;
        opacity: 0;
        margin: 0;
        padding: 10px;
        border-radius: 0 0 0 20px;
        background-color: rgba(37, 37, 37, 1);
        color: white;
        transition: opacity 0.3s;
        -webkit-transition: opacity 0.3s;
        @include media("<=tablet") {
          opacity: 1;
        }

        a {
          text-decoration: none;

          color: white;
        }
      }
      &:hover {
        a {
          .learnMore {
            opacity: 1;

            transition: opacity 0.3s;
            -webkit-transition: opacity 0.3s;
          }
        }

        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 0px 5px black;
        transition: box-shadow 0.3s;
        -webkit-transition: box-shadow 0.3s;
        transition: background-color 0.3s;
        -webkit-transition: background-color 0.3s;
      }
    }
  }
}
.buy-links {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  margin-bottom: 20px;
  @include media("<=phone") {
    flex-direction: column;
    justify-content: center;
  }
  .buy {
    flex: 1 1 25%;
    min-width: 120px;
    max-width: 50%;
    margin: auto;
    padding: 5px 10px;
    margin: 1% auto;
    border-radius: 10px;

    p {
      margin: 0;
    }
  }
  .amazon {
    background-color: rgb(255, 153, 0);
    color: #212121;
    box-shadow: 0 0 5px rgba(10, 10, 10, 0.37);
  }
  .barnes {
    background-color: #255732;
    color: white;
    box-shadow: 0 0 5px rgba(10, 10, 10, 0.37);
  }
  .bookpatch {
    background-color: #0b68d1;
    color: #f7f7f7;
    box-shadow: 0 0 5px rgba(10, 10, 10, 0.37);
  }
  .apple {
    background-color: rgb(31, 31, 31);
    color: #f4f4f4;
    box-shadow: 0 0 5px rgba(10, 10, 10, 0.37);
  }
}
.buy-anchor {
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  font-size: 1.2rem;
}

.amazon-icon {
  max-width: 40px;
  max-height: 40px;
  fill: rgb(35, 47, 62);
}
/*


KEYFRAMES



*/
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
